<template>
  <div class="pages">
    <div class="inside-nav">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        :highlight-current="true"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>

    <div class="task_box">
      <!-- 顶部按钮 -->
      <div class="oper_box">
        <div class="btns">
          <div class="el-icon el-icon-upload2"></div>
          <div @click="operDerive">导&nbsp;出</div>
        </div>
      </div>
      <!-- select 筛选 -->
      <div class="select_box">
        <div class="s_item">
          <div class="s_lable">设备名称/编号：</div>
          <div class="s_input">
            <el-input v-model="queryInfo.name"></el-input>
          </div>
        </div>
        <div class="s_item">
          <div class="s_lable">设备类型：</div>
          <el-select v-model="queryInfo.type" placeholder="请选择设备类型">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="btns" @click="getFiltrateList">查询</div>
      </div>
      <!-- 数据表格 -->
      <div class="table_boxs">
        <el-table
          ref="multipleTable"
          :data="tableList"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
        >
          <el-table-column
            align="center"
            prop="id"
            width="70"
            label="序号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="设备名称"
            width="150"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="equip_no"
            label="设备编号"
            min-width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="electricity_meter_id"
            label="电表ID"
            width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="description"
            label="描述"
            min-width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="daysElectricity"
            label="当日用电量"
            width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="monthElectricity"
            label="当月用电量"
            width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="totalElectricity"
            label="总用电量"
            width="130"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="queryInfo.page"
          @current-change="handleCurrentChange"
          next-text="下一页"
          prev-text="上一页"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      // 筛选 type select中的 列表数据
      typeList: [],
      // 查询参数对象
      queryInfo: {
        organize_id: "",
        name: "",
        type: "",
        page: 1,
        limit: 10,
      },
      // 表格列表
      tableList: [],
      // 总数据条数
      total: 0,
    };
  },
  mounted() {
    // 获取左侧二级菜单的数据
    this.getTreeTitleList();
    // 获取表格列表数据
    this.getTableList();
    // 获取 筛选类型选择项列表
    this.getTypeList();
  },
  methods: {
    // 获取左侧二级菜单的数据
    async getTreeTitleList() {
      const res = await this.$http.get("Organize/left");
      console.log(res);
      if (res.code !== 1) return this.$message.error("获取组织列表失败！");
      // this.$message.success('获取设备列表成功！')
      this.treeData = res.data;
    },

    // 获取 表格列表数据
    async getTableList() {
      const res = await this.$http.post(
        "device/energy_usage_monitor",
        this.queryInfo
      );
      if (res.code !== 1) return this.$message.error("获取设备列表失败！");
      this.tableList = res.data.data;
      this.total = res.data.total;
    },

    // 分页切换 获取列表数据
    handleCurrentChange(newPage) {
      console.log(newPage);
      this.queryInfo.page = newPage;
      this.getTableList();
    },
    // 获取 筛选select中类型数据
    async getTypeList() {
      const res = await this.$http.post("other/device_type");
      this.typeList = res.data;
    },
    // 筛选查询
    getFiltrateList() {
      this.total = 0;
      this.queryInfo.page = 1;
      this.getTableList();
    },

    // 导出
    operDerive() {
      console.log(this);
      window.location.href =
        "http://211.149.176.35:9001/api/device/export_energy_usage_monitor?organize_id=" +
        this.queryInfo.organize_id +
        "&name=" +
        this.queryInfo.name +
        "&type=" +
        this.queryInfo.type;
      // const res = await this.$http.get('device/export_energy_usage_monitor', this.queryInfo)
      // console.log(res)
    },

    // 点击副菜单进入设备详情数据
    handleNodeClick(data) {
      if (data.children) {
        // 如果不是最底层 则 获取 组织的id 然后对右侧数据进行筛选
        if (data.id) {
          this.queryInfo.organize_id = data.id;
          this.getFiltrateList();
        }
      } else {
        // 判断有没有下级，没有则为最底层数据
        this.$router.push("/monitor/detail?id=" + data.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  border: #61cdf1 2px solid;
  background-color: #1b284d;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background-color: #1b284d;
  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node > .el-tree-node__children {
  overflow: visible;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}

// 表格
.task_box {
  width: calc(100% - 220px);
  // 顶部按钮
  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      background-color: #68d8fe;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
  // select 筛选
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #68d8fe;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
.table_boxs::v-deep {
  margin-top: 20px;
  // padding: 20px 0;
  position: relative;

  // tab表格样式
  .el-table {
    width: 100%;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #32477d;
  }
  .el-table tr {
    background-color: #121d3c;
  }
  .el-table {
    color: #ffffff;
  }
  .el-table thead {
    color: #68d8fe;
  }
  .el-tabs__content {
    overflow: unset !important;
  }
  .el-table-column--selection {
    padding: 16px 10px !important;
  }
  .el-table .has-gutter .el-table__cell,
  .el-table th.el-table__cell {
    background-color: #213058;
  }
  .el-table .el-table__cell {
    // padding: 16px 0;
    border: 0;
  }
  .el-table::before,
  .el-table__fixed-right::before {
    height: 0;
  }
  tr.el-table__row--striped td.el-table__cell {
    background-color: #213058;
  }
  .handle_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .handle_btn {
      height: 26px;
      line-height: 26px;
      width: 50px;
      border-radius: 5px;
      border: 1px solid #61cdf1;
      font-size: 13px;
      cursor: pointer;
    }
    .edit {
      background-color: #61cdf1;
    }
  }
  // 分页
  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .el-pager li {
      font-size: 12px;
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      padding: 0;
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      margin: 0 7px;
    }
    .btn-next,
    .btn-prev {
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
    }
    .btn-next span,
    .btn-prev span {
      font-size: 12px;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
